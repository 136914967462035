
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WutBadge3555373895",
      {"classes":{"root":"WutBadge3555373895__root","badgeWrapper":"WutBadge3555373895__badgeWrapper","text":"WutBadge3555373895__text"},"vars":{},"stVars":{"icon-position":"var(--icon-position)","spacing":"var(--spacing)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  