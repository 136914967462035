import * as React from 'react';
import {
  FormField,
  Input,
  NumberInput,
  WixStyleReactProvider,
} from 'wix-style-react';
import type { NumberInputProps, InputStatus } from 'wix-style-react';
import {
  PREFIX_AND_SUFFIX_TYPES,
  BORDERS,
  LABEL_POSITIONS,
  SIZES,
  PrefixAndSuffixTypeOptions,
} from '../DashboardNumberInput.constants';

import type { IDashboardNumberInputProps } from '../DashboardNumberInput.props';
import { getDataAttributes, findKeyByObjectValue } from '../../../common/utils';
import { usePlatformIcon } from '../../../providers';
import { dashboardNumberInputHook } from './constants';

const STATUSES = {
  error: 'error',
  warning: 'warning',
  loading: 'loading',
} as const;

const generateIconElement = (
  icon: React.ReactNode,
  text: string,
  type: PrefixAndSuffixTypeOptions,
  iconType: 'prefix' | 'suffix',
) => {
  if (type === PREFIX_AND_SUFFIX_TYPES.icon && icon) {
    return (
      <Input.IconAffix dataHook={`dashboard-icon-${iconType}`}>
        {icon}
      </Input.IconAffix>
    );
  }

  if (type === PREFIX_AND_SUFFIX_TYPES.text) {
    return (
      <Input.Affix data-hook={`dashboard-text-${iconType}`}>{text}</Input.Affix>
    );
  }

  return null;
};

const DashboardNumberInput: React.FC<IDashboardNumberInputProps> = props => {
  const {
    id,
    className,
    value,
    label,
    showTooltip,
    tooltip,
    labelPlacement,
    placeholder,
    minAndMaxValues,
    min,
    max,
    prefixType,
    prefixIcon,
    prefixValue,
    showPrefix,
    suffixType,
    suffixIcon,
    suffixValue,
    showSuffix,
    stepSize,
    required,
    size,
    border,
    validationMessage,
    error,
    warning,
    loading,
    errorMessage,
    warningMessage,
    loadingMessage,
    disabled,
    readOnly,
    onChange,
    onKeyPress,
    onKeyRelease,
    onInvalid,
    updateComponentPropsInViewer,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const handleChange = (val: number) => {
    updateComponentPropsInViewer({ value: val });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  const _suffixIcon = usePlatformIcon(suffixIcon);
  const _prefixIcon = usePlatformIcon(prefixIcon);

  const handleInvalid: NumberInputProps['onInvalid'] = invalidValue => {
    return onInvalid?.({ type: 'invalid', value: invalidValue });
  };

  const handleKeyDown: NumberInputProps['onKeyDown'] = event => {
    return onKeyPress?.({ type: 'keyPress', key: event.key });
  };

  const handleKeyUp: NumberInputProps['onKeyUp'] = event => {
    return onKeyRelease?.({ type: 'keyRelease', key: event.key });
  };

  const inputStatus: InputStatus | undefined =
    (error && STATUSES.error) ||
    (warning && STATUSES.warning) ||
    (loading && STATUSES.loading) ||
    undefined;

  const statusMessage =
    (error && errorMessage) ||
    (warning && warningMessage) ||
    (loading && loadingMessage);

  const sizeValue = findKeyByObjectValue({ value: size, object: SIZES });

  const borderValue = findKeyByObjectValue({ value: border, object: BORDERS });

  const labelPositionValue = findKeyByObjectValue({
    value: labelPlacement,
    object: LABEL_POSITIONS,
  });

  return (
    <WixStyleReactProvider
      id={id}
      features={{ newColorsBranding: true }}
      {...getDataAttributes(props)}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <FormField
        label={label}
        infoContent={showTooltip && tooltip}
        labelPlacement={labelPositionValue}
        required={required}
      >
        <NumberInput
          value={value}
          dataHook={dashboardNumberInputHook}
          onChange={handleChange}
          placeholder={placeholder}
          min={minAndMaxValues ? min : undefined}
          max={minAndMaxValues ? max : undefined}
          prefix={
            showPrefix &&
            generateIconElement(_prefixIcon, prefixValue, prefixType, 'prefix')
          }
          suffix={
            showSuffix &&
            generateIconElement(_suffixIcon, suffixValue, suffixType, 'suffix')
          }
          step={stepSize}
          status={inputStatus}
          statusMessage={statusMessage}
          readOnly={readOnly}
          disabled={disabled}
          size={sizeValue}
          invalidMessage={validationMessage}
          border={borderValue}
          onInvalid={handleInvalid}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
        />
      </FormField>
    </WixStyleReactProvider>
  );
};

export default DashboardNumberInput;
