import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IDashboardHeadingProps,
  IDashboardHeadingMapperProps,
  IDashboardHeadingControllerProps,
  IDashboardHeadingStateValues,
} from '../../../components/DashboardHeading/DashboardHeading.props';

export default withCompController<
  IDashboardHeadingMapperProps,
  IDashboardHeadingControllerProps,
  IDashboardHeadingProps,
  IDashboardHeadingStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
