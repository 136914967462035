import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IDashboardTextButtonProps,
  IDashboardTextButtonMapperProps,
  IDashboardTextButtonControllerProps,
  IDashboardTextButtonStateValues,
} from '../../../components/DashboardTextButton/DashboardTextButton.props';

export default withCompController<
  IDashboardTextButtonMapperProps,
  IDashboardTextButtonControllerProps,
  IDashboardTextButtonProps,
  IDashboardTextButtonStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
