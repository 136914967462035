import * as React from 'react';
import { FormField, Input, WixStyleReactProvider } from 'wix-style-react';
import type { InputStatus, InputProps } from 'wix-style-react';
import type { IDashboardInputProps } from '../DashboardInput.props';
import {
  PrefixAndSuffixTypeOptions,
  SIZES,
  PREFIX_AND_SUFFIX_TYPES,
  BORDERS,
  LABEL_POSITIONS,
} from '../DashboardInput.constants';
import { getDataAttributes, findKeyByObjectValue } from '../../../common/utils';
import { usePlatformIcon } from '../../../providers';
import { dashboardFormFieldHook, dashboardInputHook } from './constants';

const STATUSES = {
  error: 'error',
  warning: 'warning',
  loading: 'loading',
} as const;

const generateIconElement = (
  icon: React.ReactNode,
  text: string,
  type: PrefixAndSuffixTypeOptions,
  iconType: 'prefix' | 'suffix',
) => {
  if (type === PREFIX_AND_SUFFIX_TYPES.icon && icon) {
    return (
      <Input.IconAffix dataHook={`dashboard-icon-${iconType}`}>
        {icon}
      </Input.IconAffix>
    );
  }

  if (type === PREFIX_AND_SUFFIX_TYPES.text) {
    return <Input.Affix>{text}</Input.Affix>;
  }

  return null;
};

const getTransFormedValue = ({
  value,
  maxLength,
}: {
  value: string;
  maxLength?: number;
}) => {
  if (maxLength && value.length > maxLength) {
    return value.substring(0, maxLength);
  }

  return value;
};
/**
 * As a rule of thumb, we use functional components, and use React's hooks and forwardRef for more advanced capabillities.
 * To learn more about React components guidelines, read [the code-guidelines](https://bo.wix.com/pages/editor-elements-docs/docs/development/contributing#code-guidelines)
 */
const DashboardInput: React.FC<IDashboardInputProps> = props => {
  const {
    id,
    className,
    label,
    value,
    placeholder,
    prefixIcon,
    prefixValue,
    prefixType,
    suffixIcon,
    suffixType,
    showSuffix,
    suffixValue,
    setMaxLength,
    showPrefix,
    onChange,
    maxLength,
    size,
    labelPosition,
    required,
    tooltip,
    showTooltip,
    border,
    error,
    warning,
    loading,
    errorMessage,
    warningMessage,
    loadingMessage,
    disabled,
    readOnly,
    updateComponentPropsInViewer,
    onKeyPress,
    onKeyRelease,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const [charCount, setCharCount] = React.useState(0);
  const [localValue, setLocalValue] = React.useState('');

  const platformPrefixIcon = usePlatformIcon(prefixIcon);
  const platformSuffixIcon = usePlatformIcon(suffixIcon);

  React.useEffect(() => {
    const transformedValue = getTransFormedValue({
      value,
      maxLength: setMaxLength ? maxLength : undefined,
    });
    setLocalValue(transformedValue);
  }, [value, maxLength, setMaxLength]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateComponentPropsInViewer({
      value: getTransFormedValue({
        value: event.target.value,
        maxLength: setMaxLength ? maxLength : undefined,
      }),
    });
    setCharCount(event.target.value.length);
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  const handleKeyDown: InputProps['onKeyDown'] = event => {
    return onKeyPress?.({ type: 'keyPress', key: event.key });
  };

  const handleKeyUp: InputProps['onKeyUp'] = event => {
    return onKeyRelease?.({ type: 'keyRelease', key: event.key });
  };

  const charCountProp =
    maxLength === 0 || !setMaxLength ? undefined : maxLength - charCount;

  const inputStatus: InputStatus | undefined =
    (error && STATUSES.error) ||
    (warning && STATUSES.warning) ||
    (loading && STATUSES.loading) ||
    undefined;

  const statusMessage =
    (error && errorMessage) ||
    (warning && warningMessage) ||
    (loading && loadingMessage);

  const sizeValue = findKeyByObjectValue({ value: size, object: SIZES });
  const borderValue = findKeyByObjectValue({ value: border, object: BORDERS });

  const labelPositionValue = findKeyByObjectValue({
    value: labelPosition,
    object: LABEL_POSITIONS,
  });

  return (
    <WixStyleReactProvider
      id={id}
      features={{ newColorsBranding: true }}
      {...getDataAttributes(props)}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <FormField
        dataHook={dashboardFormFieldHook}
        label={label}
        infoContent={showTooltip && tooltip}
        labelPlacement={labelPositionValue}
        charCount={charCountProp}
        required={required}
      >
        <Input
          dataHook={dashboardInputHook}
          maxLength={maxLength}
          value={localValue}
          placeholder={placeholder}
          onChange={handleOnChange}
          status={inputStatus}
          statusMessage={statusMessage}
          size={sizeValue}
          readOnly={readOnly}
          disabled={disabled}
          prefix={
            showPrefix &&
            generateIconElement(
              platformPrefixIcon,
              prefixValue,
              prefixType,
              'prefix',
            )
          }
          suffix={
            showSuffix &&
            generateIconElement(
              platformSuffixIcon,
              suffixValue,
              suffixType,
              'suffix',
            )
          }
          border={borderValue}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
        />
      </FormField>
    </WixStyleReactProvider>
  );
};

export default DashboardInput;
