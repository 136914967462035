import * as React from 'react';
import type { ManagedOptionDataType } from '@wix/editor-elements-types/leanComponents';
import { HorizontalTabs, HorizontalTabsProps } from '@wix/wix-base-ui/lib/esm';
import type {
  IWbuHorizontalTabsProps,
  TabsStyle,
} from '../WbuHorizontalTabs.props';
import './WbuHorizontalTabs.global.scss';
import WbuContainer from '../../WbuContainer/WbuContainer';
import { getDataAttributes } from '../../../common/utils';

const WbuHorizontalTabs: React.FC<IWbuHorizontalTabsProps> = props => {
  const {
    id,
    className,
    options,
    tabsStyle,
    updateComponentPropsInViewer,
    onChange,
    maxVisibleTabs,
    tabsToScroll,
  } = props;

  const _onChange = (selectedOptionValue: string) => {
    const newOptions = options.map(option => {
      if (option.value === selectedOptionValue) {
        return { ...option, isDefault: true };
      } else {
        return { ...option, isDefault: false };
      }
    });
    updateComponentPropsInViewer({
      options: newOptions,
    });

    onChange?.({ type: 'change' } as React.ChangeEvent);
  };
  const baseUITypes: Record<TabsStyle, HorizontalTabsProps['type']> = {
    Folder: 'tabbed',
    Arrowed: 'arrowed',
    'Arrowed Without a Line': 'arrowedNoLine',
  };
  const selectedOption = options.find(
    (option: ManagedOptionDataType) => option.isDefault,
  );

  return (
    <WbuContainer id={id} {...getDataAttributes(props)} className={className}>
      <HorizontalTabs
        options={options}
        value={selectedOption ? selectedOption.value : undefined}
        onChange={_onChange}
        type={baseUITypes[tabsStyle]}
        maxVisibleTabs={maxVisibleTabs}
        tabsToSkipOnClick={tabsToScroll}
      />
    </WbuContainer>
  );
};

export default WbuHorizontalTabs;
