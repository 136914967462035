import * as React from 'react';
import {
  Composites,
  TextLabel,
  InfoIcon,
  Slider,
  SliderProps,
} from '@wix/wix-base-ui/lib/esm';
import type { IWbuSliderProps } from '../WbuSlider.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuSlider.global.scss';
import { getDataAttributes } from '../../../common/utils';

const unitsMap: Record<
  IWbuSliderProps['unitOfMeasurement'],
  string | undefined
> = {
  number: undefined,
  percentage: 'percent',
  pixels: 'px',
  angle: 'angle',
};

const WbuSlider: React.FC<IWbuSliderProps> = props => {
  const {
    id,
    className,
    fieldTitle,
    tooltipInfo,
    defaultSelectedValue,
    minimumValue,
    maximumValue,
    unitOfMeasurement,
    onChange,
    showNumericInput,
    stepValue,
    divider,
    isDisabled,
    onMouseEnter,
    onMouseLeave,
    updateComponentPropsInViewer,
  } = props;

  const _onChange = (val: number) => {
    updateComponentPropsInViewer({ defaultSelectedValue: val });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Composites.SliderLabeled>
        {tooltipInfo && <InfoIcon text={tooltipInfo} />}
        {fieldTitle && <TextLabel value={fieldTitle} />}
        <Slider
          hideNumericInput={!showNumericInput}
          value={defaultSelectedValue}
          min={minimumValue}
          max={maximumValue}
          step={stepValue}
          unit={unitsMap[unitOfMeasurement] as SliderProps['unit']}
          onChange={_onChange}
          disabled={isDisabled}
        />
      </Composites.SliderLabeled>
    </WbuContainer>
  );
};

export default WbuSlider;
