import * as React from 'react';
import { CSSProperties } from 'react';
import { Divider } from '@wix/wix-base-ui/lib/esm';
import { getDataAttributes } from '../../common/utils';
import { classes, st } from './style/WbuContainer.st.css';

const WbuContainer: React.FC<{
  children?: React.ReactElement;
  id: string;
  classes?: Array<string>;
  style?: CSSProperties;
  divider?: 'none' | 'short' | 'long';
  className?: string;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
}> = props => {
  const {
    id,
    children,
    className,
    classes: otherClasses = [],
    style,
    divider = 'none',
    onMouseEnter = () => {},
    onMouseLeave = () => {},
  } = props;

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={st(classes.root, ...otherClasses, className)}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
      {divider !== 'none' && (
        <Divider dataHook="container-divider" long={divider === 'long'} />
      )}
    </div>
  );
};

export default WbuContainer;
