import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IDashboardButtonProps,
  IDashboardButtonMapperProps,
  IDashboardButtonControllerProps,
  IDashboardButtonStateValues,
} from '../../../components/DashboardButton/DashboardButton.props';

export default withCompController<
  IDashboardButtonMapperProps,
  IDashboardButtonControllerProps,
  IDashboardButtonProps,
  IDashboardButtonStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues;
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    },
  };
});
