type CompositionType = 'TextAndIcon' | 'Text' | 'Icon' | 'None';

const isTextComposition = (composition: CompositionType): boolean => {
  return ['TextAndIcon', 'Text'].includes(composition);
};

const isIconComposition = (composition: CompositionType): boolean => {
  return ['TextAndIcon', 'Icon'].includes(composition);
};

export type { CompositionType };
export { isIconComposition, isTextComposition };
