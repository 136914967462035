import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';

const props = withCompInfo<any, any>()(
  ['compData', 'compStylableClass'],
  ({ compData, compStylableClass }) => {
    return {
      ...{ alt: '', composition: 'TextAndIcon', icon: {}, text: 'My Badge' },
      ...compData,
      stylableClassName: compStylableClass,
    };
  },
);

const css = withCompInfo<any, any>()(
  ['styleProperties'],
  ({ styleProperties }) => {
    const { spacing = 8, iconPosition = 'row' } = styleProperties;
    return {
      '--spacing': spacing,
      '--icon-position': iconPosition,
    };
  },
);

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, css, stateRefs });
