import * as React from 'react';
import {
  Composites,
  TextLabel,
  FontFamilyPicker,
  ColorPickerInput,
  InfoIcon,
} from '@wix/wix-base-ui/lib/esm';

import { getCurrentSelectablefontsWithParams } from '@wix/santa-core-utils/dist/fonts';
import WbuContainer from '../../WbuContainer/WbuContainer';
import type { IWbuFontAndColorPickerProps } from '../WbuFontAndColorPicker.props';

import './WbuFontAndColorPicker.global.scss';
import { getDataAttributes } from '../../../common/utils';

const fonts = getCurrentSelectablefontsWithParams('UGC', ['latin']);

const WbuFontAndColorPicker: React.FC<IWbuFontAndColorPickerProps> = props => {
  const {
    id,
    className,
    label,
    color,
    font,
    divider,
    tooltip,
    updateComponentPropsInViewer,
    onClick,
    onColorChange,
    onFontChange,
  } = props;

  const [isMount, setIsMount] = React.useState(false);
  React.useEffect(() => {
    if (isMount) {
      onColorChange?.({ type: 'onColorChange' });
    }
    setIsMount(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const _onFontChange = (val: string) => {
    updateComponentPropsInViewer({ font: val });
    onFontChange?.({ type: 'onFontChange' });
  };

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
    >
      <Composites.DropDownWithColorInputLabeled>
        <InfoIcon text={tooltip} />
        <TextLabel type="T02" value={label} />
        <Composites.DropDownWithColorInput>
          <FontFamilyPicker
            fonts={fonts}
            value={font}
            getMissingFontName={() => {
              return 'Arial';
            }}
            onChange={_onFontChange}
            shouldTranslate={false}
          />
          <ColorPickerInput value={color} onClick={onClick} />
        </Composites.DropDownWithColorInput>
      </Composites.DropDownWithColorInputLabeled>
    </WbuContainer>
  );
};

export default WbuFontAndColorPicker;
